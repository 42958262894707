<!--就餐服务 核查记录 列表-->
<template>
    <div>
        <div class="containor">
            <tabbed-page
                :tabList="tabList"
                @changeTabs="changeTabs"
            >
            </tabbed-page>
            <div class="page-box">
				<keep-alive>
					<component ref="DataList" :is="comps[idx]" @switchClick="switchClick" @checkingInIdChange="checkingInIdChange"
						@routerReturnChange="routerReturnChange" :checkingInId="checkingInId" :routerReturn="routerReturn">
					</component>
				</keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
import DataList from './CheckRecordList/DataList.vue'
import StudentList from './CheckRecordList/StudentList.vue';
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';

export default {
    name: "CheckRecordListist",
    props: {
        listData: {
            type: Object
        }
    },
    components: {
        DataList,
        StudentList,
        TabbedPage : () => import(/* webpackChunkName:'TabbedPage' */"../Sub/TabbedPage/index.vue")
    },
    data() {
        return {
            currentComponent: "DataList",
			checkingInId: undefined,
			routerReturn: undefined,
			comps: [
                'DataList',
                'StudentList',
            ],
			tabList: [
				{
					id: 0,
					title: '按天',
					com: 'DataList',
                    vHasPermi: ['checkRecord:repast:day'],
					sel:true
				},
				{
					id: 1,
					title: '按学生',
					com: 'StudentList',
                    vHasPermi: ['checkRecord:repast:student'],
					sel:false
				},
			],
			idx: 0
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map(item => item.com);
            console.log(this.tabList,  this.comps, ' this.comps++')
        },
        /**
		 * @Description: 切换tab
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 武东超
		 * @Date: 2023-08-16 10:01:06
		 */
		changeTabs (data) {
			this.tabList.forEach((item, index) => {
				if (item.id === data.id) {
					this.$set(item, 'sel', true)
					this.idx = index;
				} else {
					this.$set(item, 'sel', false)
				}
			})
		},
		switchClick(page) {
			this.currentComponent = page
		},
		checkingInIdChange(id) {
			this.checkingInId = id
		},
		routerReturnChange(path) {
			this.routerReturn = path
		}
    },
	activated(){
		setTimeout(() => {
			this.$nextTick(() => {
				if(this.idx == 0){
					this.tabList.forEach(item => {
						item.id == 0 && (item.sel = true)
						item.id != 0 && (item.sel = false)
					})
				}else{
					this.tabList.forEach(item => {
						item.id != 1 && (item.sel = false)
						item.id == 1 && (item.sel = true)
					})
				}
				this.$forceUpdate();
			})
		},200)

	}
}
</script>

<style scoped lang="scss">
::v-deep{
	.containor{
		// background-color: #edf0f7 !important;
	}
    .content-wrapper{
        margin-top: 0;
        border-radius: 0 !important;
        // .expand-filter{
        //     border-radius: 0 !important;
		//     margin-bottom: 1px !important;
        // }
        .table-paging{
            border-radius: 0;
        }
    }
}
.tabs-list {
	height: 48px;
	display: flex;
	padding: 0 30px;
	line-height: 48px;
	align-items: center;
	margin-bottom: 10px;
	background-color: #FFFFFF;
	border-radius: 4px 0px 0px 4px;

	.switch-item {
		color: #333333;
		cursor: pointer;
		font-size: 14px;
		margin-right: 48px;
	}

	.current-item {
		position: relative;
		color: #3C7FFF;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background-color: #3C7FFF;
			border-radius: 1px;
		}
	}
}

.page-box {
	margin-right: 10px;
}
</style>
