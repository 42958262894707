<template>
    <keep-alive>
        <component
            ref="checkRecord"
            v-bind:is="currentTabComponent"
            :detail-data="detailData"
            :list-data="listData"
            @toList="toList"
            @toDetail="toDetail"
            @back="back"
            @setPageInitTabs="setPageInitTabs"
    ></component>
    </keep-alive>
</template>
<script>
// 列表
import CheckRecordList from './RepastCheckRecord/CheckRecordList'
// 详情
import CheckRecordDetail from './RepastCheckRecord/CheckRecordDetail'
export default {
    name: "CheckRecordScrollWrapper",
    components: {
        CheckRecordList,
        CheckRecordDetail,
    },
    data() {
        return {
            // 切换组件
            compList: ['CheckRecordList', 'CheckRecordDetail'],
            idx: 0,
            listData: {},
            detailData: {},
            type: 'info'
        }
    },
    computed: {
        currentTabComponent() {
            return this.compList[this.idx];
        }
    },
    methods: {
        toList(listData) {
            this.idx = 0;
            this.listData = listData;

            this.$nextTick(() => {
                this.$refs.checkRecord.init()
                this.$refs.checkRecord.$refs.DataList.getList(1)           
            })
        },
        toDetail(detailData) {
            this.idx = 1
            this.detailData = detailData
        },
        back () {
            this.toList({typeId: this.detailData.typeId})
        },
        setPageInitTabs() {
            this.idx = 0;
            this.$eventDispatch('setGlobalPageType', 'list')
        }
    }
}
</script>
